import React from 'react';
import { useTranslation } from 'react-i18next';
import { useUser } from '@engage-web/api/queries';
import { Avatar } from '../../../../base/Avatar/Avatar';
import { UNKNOWN_PERSON } from '@engage-web/constants';
import { isElectron } from '@engage-web/utils/electron';
import { NameWrapper, Title, Wrapper, FullName } from './styled';

type BookedButtonProps = {
  id?: number;
  firstName?: string;
  lastName?: string;
  imageUrl?: string;
  onPress?: () => void;
  isSvLiveOccupant?: boolean;
  forceToGuest?: boolean;
};

const BookedButton = ({
  id: personId = 0,
  isSvLiveOccupant,
  firstName,
  lastName,
  imageUrl,
  onPress,
  forceToGuest,
}: BookedButtonProps) => {
  const { t } = useTranslation();
  const { data: user } = useUser();

  const currentPersonId = user?.personId;
  const isCurrentPerson = personId === currentPersonId;

  let title = t('layout.reserve.bookedFor');
  if (isCurrentPerson || isSvLiveOccupant) {
    title = t('layout.reserve.by');
  }

  const isKioskUser = isElectron() && (isCurrentPerson || forceToGuest);

  const fullName = isKioskUser ? t('layout.reserve.guest') : `${firstName} ${lastName}`;
  const image = isKioskUser ? null : imageUrl;
  const icon = isKioskUser ? 'personNew' : null;
  // UNKNOWN_PERSON is returned as firstName lastName if user is opt outed
  // In required fields for firstName and lastName in engage configuration spaces can be added
  const nameExists = fullName?.trim() && fullName !== UNKNOWN_PERSON;
  const personName = nameExists ? fullName : t('layout.reserve.incognitoUser');

  return (
    <Wrapper>
      <Title>{`${title}: `}</Title>
      <NameWrapper data-testid="booked-by-button" onClick={onPress}>
        <Avatar source={image} name={personName} icon={icon} size="tiny" />
        <FullName>{personName}</FullName>
      </NameWrapper>
    </Wrapper>
  );
};

export default BookedButton;
