import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { tenantActions, tenantSelectors, floorplanActions } from '@engage-web/store';
import { Mask } from '@engage-web/components/base';
import { ROOT_PATH } from '@engage-web/constants';
import { useGenerateCurrentLocationPath } from '@engage-web/utils/hooks';
import { Main } from '@engage-web/components/scenes/Main';
import { mapLocationFloorData } from '@engage-shared/utils';
import { useFloorFetch, prefetchFloorPermissions } from '@engage-shared/api';
import { useFloorCheck } from '../hooks/useFloorCheck';
import { isElectron } from '@engage-web/utils/electron';
import { useQueryClient } from 'react-query';
import { useCallback, useEffect } from 'react';

/**
 * Check location path '/floor/:floorId' is a correct location.
 * A correct location is when floor exists in a building.
 *
 * This effect is triggered when user has no current location
 * or user current location exists and the floor location url is different.
 * In both cases, the current location is updated.
 *
 * Until floor location is checked and set on the UI the loader spinner is displayed.
 * After the location is set the router routes to Main.
 *
 * If error occurs when setting the location the router redirects to user current location or root path.
 */
export const FloorCheckRouter = () => {
  const dispatch = useDispatch();
  const isKiosk = isElectron();

  const currentLocationPath = useGenerateCurrentLocationPath();

  const navigate = useNavigate();
  const { checkLocationQuery } = useFloorCheck();
  const { fetchFloor } = useFloorFetch();

  const userCurrentLocation = useSelector(tenantSelectors.getCurrentLocation);
  const { building, floor } = userCurrentLocation;
  const hasLocation = !!(building && floor);

  const newFloorId = useParams().floorId; // From URL

  const locationUrlExists = !!newFloorId;

  const queryClient = useQueryClient();

  const isSameLocation = newFloorId === `${userCurrentLocation?.floor?.id}`;

  const locationChanged = locationUrlExists && (!hasLocation || (hasLocation && !isSameLocation));

  const setNewUserLocation = useCallback(async () => {
    const search = window.location.hash;
    if (isKiosk && !search.includes('floor')) {
      return;
    }

    const [error, verifiedLocation] = await checkLocationQuery(newFloorId);
    if (verifiedLocation) {
      const building = verifiedLocation.building;
      const floor = verifiedLocation.floor;

      if (building && floor) {
        // get floor name for InfoCard
        const floorData = await fetchFloor({
          buildingId: building.id,
          floorId: floor.id,
        });

        prefetchFloorPermissions({ queryClient, floorId: floor.id });
        dispatch(
          tenantActions.setCurrentLocation({
            building,
            floor: mapLocationFloorData(floorData ?? floor),
          }),
        );

        // Reset selected locations when the floor changes
        dispatch(floorplanActions.setFocusedSpace(null));
      }
    } else if (error) {
      // reset url to current location or root path
      navigate(currentLocationPath || ROOT_PATH, { replace: true });
    }
  }, [checkLocationQuery, currentLocationPath, dispatch, fetchFloor, navigate]);

  useEffect(() => {
    (async () => {
      if (locationChanged && !isSameLocation) {
        await setNewUserLocation();
      }
    })();
  }, [locationChanged, setNewUserLocation, isSameLocation]);

  if (locationChanged) {
    return <Mask isLoading />;
  }

  return <Main />;
};
