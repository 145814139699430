import React, { CSSProperties, memo } from 'react';
import svgIcons from './svgIcons';

export type EngageIcons = (typeof svgIcons)[number];

type IconProps = {
  name: EngageIcons;
  size?: number;
  className?: string;
  style?: CSSProperties;
  shouldRotateOnRtl?: boolean;
};

export const Icon = memo(({ name, size = 30, className, style, shouldRotateOnRtl }: IconProps) => {
  const isLayoutRTL = document.getElementsByTagName('body')[0].getAttribute('dir') === 'rtl';
  return (
    <svg
      {...(shouldRotateOnRtl && isLayoutRTL && { transform: 'rotate(180)' })}
      aria-hidden
      focusable={false}
      data-testid={`icons-${name}-svg`}
      className={className}
      width={size}
      height={size}
      style={{
        // flex is set in order to prevent item from shrinking
        // when placed inside flex container
        flex: `0 0 ${size}px`,
        ...style,
      }}
    >
      <use xlinkHref={`#${name}`} />
    </svg>
  );
});
