import React from 'react';
import { Label as LabelType } from '../../types';
import { useFESContext } from '@floorplan/fes';

const DEFAULT_LABEL_SIZE = 16;

interface CustomStyle {
  width?: number;
  height?: number;
  additionalOffsetY?: number;
}

interface LabelProps {
  label: LabelType;
  lineIndex: number;
  offsetY: number;
  lineHeight?: number;
  labelSize?: number;
  maxLabelChars?: number;
  maxLabelWidth?: number;
  labelBoxColor?: string;
  fillColor?: string;
  customStyle?: CustomStyle;
  angle?: number;
}
export const Label = ({
  label,
  lineIndex,
  labelSize = DEFAULT_LABEL_SIZE,
  lineHeight,
  offsetY,
  maxLabelChars = 30,
  labelBoxColor = '#0D142F',
  fillColor = '#FFFFFF',
  customStyle,
  angle = 0,
}: LabelProps) => {
  const labelRef = React.useRef<SVGTextElement | null>(null);
  const [textWidth, setTextWidth] = React.useState<number | null>(null);

  const {
    width: additionalWidth = 2,
    height: additionalHeight = 4,
    additionalOffsetY = 0,
  } = customStyle || {};

  const fes = useFESContext();

  React.useEffect(() => {
    // measure text width
    const textElement = labelRef.current;
    const textElWidth = textElement ? textElement.getBBox?.().width : 0;
    setTextWidth(textElWidth);
  }, []);

  const defaultLabelStyle = () => ({
    fill: fillColor,
    fontSize: labelSize,
  });

  const getLabelStyle = () => {
    const defaultStyle = defaultLabelStyle();
    const labelStyle = label.lines[lineIndex].style;
    const position = { textAnchor: 'middle' };

    return {
      ...defaultStyle,
      ...labelStyle,
      ...position,
    } as React.CSSProperties;
  };

  const exceedsMaxLabelChars = () =>
    maxLabelChars && label.lines[lineIndex].text.length > maxLabelChars;

  const getLabelText = () => {
    const ellipsis = '...';
    const labelText = label.lines[lineIndex].text;

    return exceedsMaxLabelChars()
      ? `${labelText.substring(0, maxLabelChars - ellipsis.length)}${ellipsis}`
      : labelText;
  };

  const labelBoxStyle = () => ({
    fill: labelBoxColor || 'white',
    fillOpacity: 0.7,
  });

  const renderLabelBox = (width: number | null) => {
    const boxHeight = labelSize + additionalHeight;
    const rotation = fes.getViewerViewport()?.getRotation() || -angle;
    return width ? (
      <rect
        x={label.coordinate.x - width / 2}
        y={label.coordinate.y + offsetY - boxHeight / 2}
        rx="3"
        ry="3"
        width={width}
        height={boxHeight}
        style={labelBoxStyle()}
        transform={`rotate(${-(rotation ?? 0)} ${label.coordinate.x} ${label.coordinate.y})`}
      >
        {exceedsMaxLabelChars() && <title>{label.lines[lineIndex].text}</title>}
      </rect>
    ) : null;
  };

  const renderText = () => {
    const labelSizeOffset = 5 - Math.ceil((DEFAULT_LABEL_SIZE - labelSize) / 4);
    const calcLineHeight = lineHeight || 20 - (DEFAULT_LABEL_SIZE - labelSize);
    const calcOffsetY =
      offsetY || lineIndex * calcLineHeight - ((label.lines.length - 1) * calcLineHeight) / 2;

    const rotation = fes.getViewerViewport()?.getRotation() || -angle;
    return (
      <text
        ref={labelRef}
        x={label.coordinate.x}
        y={label.coordinate.y + calcOffsetY + labelSizeOffset + additionalOffsetY}
        style={getLabelStyle()}
        transform={`rotate(${-(rotation ?? 0)} ${label.coordinate.x} ${label.coordinate.y})`}
        data-offset={calcOffsetY + labelSizeOffset}
      >
        {getLabelText()}
      </text>
    );
  };

  const width = textWidth ? textWidth + additionalWidth : null;

  return (
    <>
      {renderLabelBox(width)}
      {renderText()}
    </>
  );
};
