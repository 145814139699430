import OpenSeadragon from 'openseadragon';
import { CustomGestureSettings } from '../../types';
import { DEFAULT_ANGLE_COEFFICIENT } from '../../constants';
import { debounce, isNativeApp, zoomLevel } from '../../utils';
import { FES, FES_EVENTS } from '@floorplan/fes';

interface ViewerHandlerDelegate {
  onRotationChange: (rotation: number) => void;
}

// Used to determine if rotation or zoom event was the user's intention.
// Gesture track time is the amount of time the events can fire before deciding.
const GESTURE_TRACK_TIME = 200;
// Gesture min distance is the minimum required distance of the pinch gesture before
// deciding this is a zoom instead of rotate.
const GESTURE_MIN_DISTANCE = 40;
const GESTURE_STATE = {
  NONE: 'none',
  ZOOM: 'zoom',
  ROTATION: 'rotation',
} as const;

const isKeyboardRotationActivated = (
  event: OpenSeadragon.CanvasDragEvent | OpenSeadragon.CanvasPressEvent,
) =>
  window.navigator.platform.toUpperCase().indexOf('MAC') >= 0
    ? (event.originalEvent as KeyboardEvent).metaKey
    : (event.originalEvent as KeyboardEvent).ctrlKey;

//TODO move this to FES viewer
export class ViewerHandler {
  private startTimestamp: number;
  private startDistance: number;
  private startPoint: number;
  private dragRotation: number;
  private rotation: number;
  private debouncedFesTrigger?: (rotation: number) => void;
  private isTracking: boolean;
  private state: (typeof GESTURE_STATE)[keyof typeof GESTURE_STATE];
  private readonly fes: FES;
  private readonly viewer: OpenSeadragon.Viewer;
  private _delegate?: ViewerHandlerDelegate = undefined;
  private _labelsShown = false;

  constructor(fes: FES, viewer: OpenSeadragon.Viewer, delegate: ViewerHandlerDelegate) {
    this.startTimestamp = Date.now();
    this.startDistance = 0;
    this.startPoint = 0;
    this.dragRotation = 0;
    this.rotation = 0;
    this.isTracking = false;
    this.state = GESTURE_STATE.NONE;

    this.fes = fes;
    this.viewer = viewer;
    this._delegate = delegate;
  }

  addHandlers = () => {
    this.debouncedFesTrigger = debounce(rotation => {
      this.fes.trigger(FES_EVENTS.ROTATION_CHANGED, { rotation });
    }, 250);
    this.viewer.addHandler('open', this.handleOpenEvent);
    this.viewer.addHandler('canvas-pinch', this.handleCanvasPinchedEvent);
    this.viewer.addHandler('canvas-release', this.handleCanvasReleasedEvent);
    this.viewer.addHandler('zoom', this.handleZoomEvent);
    this.viewer.addHandler('rotate', this.handleRotateEvent);

    const handleAnimationFinishEvent = () => {
      this.viewer.addHandler('canvas-click', this.handleCanvasClickEvent);
      // ! TODO send to native ?
      // FES.trigger(FES_EVENTS.FLOORPLAN_READY);
      // ? reset rotation?
      this.fes.trigger(FES_EVENTS.ROTATION_CHANGED, { rotation: 0 });
      this.viewer.removeHandler('animation-finish', handleAnimationFinishEvent);
    };
    this.viewer.addHandler('animation-finish', handleAnimationFinishEvent);
    if (!isNativeApp) {
      this.viewer.addHandler('canvas-drag', this.handleCanvasDragEvent);
      this.viewer.addHandler('canvas-press', this.handleCanvasPressEvent);
      this.viewer.addHandler('canvas-drag-end', this.handleCanvasDragEndEvent);
    }
  };

  getViewer = () => {
    return this.viewer;
  };

  removeHandlers = () => {
    this.viewer.removeHandler('open', this.handleOpenEvent);
    this.viewer.removeHandler('canvas-pinch', this.handleCanvasPinchedEvent);
    this.viewer.removeHandler('canvas-click', this.handleCanvasClickEvent);
    this.viewer.removeHandler('canvas-release', this.handleCanvasReleasedEvent);
    this.viewer.removeHandler('zoom', this.handleZoomEvent);
    this.viewer.removeHandler('rotate', this.handleRotateEvent);
    if (!isNativeApp) {
      this.viewer.removeHandler('canvas-drag', this.handleCanvasDragEvent);
      this.viewer.removeHandler('canvas-press', this.handleCanvasPressEvent);
      this.viewer.removeHandler('canvas-drag-end', this.handleCanvasDragEndEvent);
    }
  };

  handleCanvasPinchedEvent = (event: OpenSeadragon.CanvasPinchEvent) => {
    const timestamp = Date.now();
    if (!this.isTracking) {
      this.startTimestamp = timestamp;
      this.startDistance = event.distance;
      this.state = GESTURE_STATE.NONE;
      this.isTracking = true;
    } else if (
      this.state === GESTURE_STATE.NONE &&
      timestamp - this.startTimestamp >= GESTURE_TRACK_TIME
    ) {
      if (Math.abs(this.startDistance - event.distance) >= GESTURE_MIN_DISTANCE) {
        this.state = GESTURE_STATE.ZOOM;
      } else {
        this.state = GESTURE_STATE.ROTATION;
      }
    }

    this.updateViewerWithGestureState();
  };

  handleCanvasReleasedEvent = () => {
    if (this.isTracking) {
      this.state = GESTURE_STATE.NONE;
      this.isTracking = false;

      this.updateViewerWithGestureState();
    }
  };

  handleOpenEvent = () => {
    const minZoom = this.viewer?.viewport.getMinZoom();
    this.viewer?.viewport.zoomTo(minZoom);
  };

  handleRotateEvent = (event: OpenSeadragon.RotateEvent) => {
    const { degrees } = event;
    this.rotation = -degrees;
    this._delegate?.onRotationChange(-this.viewer.viewport.getRotation());
    if (this.debouncedFesTrigger) {
      this.debouncedFesTrigger(degrees);
    }
  };

  handleZoomEvent = (event: OpenSeadragon.ZoomEvent) => {
    const { zoom } = event;
    const maxZoom = this.viewer.viewport.getMaxZoom();
    const showLabels = zoom >= zoomLevel * maxZoom;
    // send showLabels event only if value changes in order to reduce load on js thread
    if (showLabels !== this._labelsShown) {
      this._labelsShown = showLabels;
      this.fes.trigger(FES_EVENTS.SHOW_LABELS, { showLabels });
    }
  };

  handleCanvasClickEvent = (event: OpenSeadragon.CanvasClickEvent) => {
    // This is a very bad hack to allow clicking on floorplan for iOS 12 safari
    // This goes around an issue that older safari's had where an svg elements children
    // Cannot have a click event, only touch events.
    // Flag variable is set through engage
    if ((window as any).FLAG_ios12Compatibility) {
      if (event?.quick) {
        const mouseEvent = new MouseEvent('click', event?.originalEvent);
        if (event?.originalEvent?.target) {
          event.originalEvent.target.dispatchEvent(mouseEvent);
        }
      }
    }
  };

  handleCanvasPressEvent = (event: OpenSeadragon.CanvasPressEvent) => {
    if (!isKeyboardRotationActivated(event)) return;
    this.startPoint = event.position.x;
  };

  handleCanvasDragEvent = (event: OpenSeadragon.CanvasDragEvent) => {
    if (!isKeyboardRotationActivated(event)) return;
    event.preventDefaultAction = true;
    this.viewer.viewport.setRotation(
      DEFAULT_ANGLE_COEFFICIENT * (event.position.x - this.startPoint) + this.dragRotation,
    );
  };

  handleCanvasDragEndEvent = () => {
    // Saving previous rotation
    this.dragRotation = this.viewer.viewport.getRotation();
  };

  updateViewerWithGestureState() {
    switch (this.state) {
      case GESTURE_STATE.ZOOM:
        (this.viewer.gestureSettingsByDeviceType('touch') as CustomGestureSettings).pinchRotate =
          false;
        this.viewer.gestureSettingsByDeviceType('touch').pinchToZoom = true;
        (this.viewer.gestureSettingsByDeviceType('mouse') as CustomGestureSettings).pinchRotate =
          false;
        this.viewer.gestureSettingsByDeviceType('mouse').pinchToZoom = true;
        break;
      case GESTURE_STATE.ROTATION:
        (this.viewer.gestureSettingsByDeviceType('touch') as CustomGestureSettings).pinchRotate =
          true;
        this.viewer.gestureSettingsByDeviceType('touch').pinchToZoom = true;
        (this.viewer.gestureSettingsByDeviceType('mouse') as CustomGestureSettings).pinchRotate =
          true;
        this.viewer.gestureSettingsByDeviceType('mouse').pinchToZoom = true;
        break;
      case GESTURE_STATE.NONE:
      default:
        (this.viewer.gestureSettingsByDeviceType('touch') as CustomGestureSettings).pinchRotate =
          false;
        this.viewer.gestureSettingsByDeviceType('touch').pinchToZoom = false;
        (this.viewer.gestureSettingsByDeviceType('mouse') as CustomGestureSettings).pinchRotate =
          false;
        this.viewer.gestureSettingsByDeviceType('mouse').pinchToZoom = false;
        break;
    }
  }
}
