import { useEffect, useState } from 'react';
import { FES_EVENTS, useFESContext } from '@floorplan/fes';
import { zoomLevel } from '../utils';

export const useShowLabels = () => {
  const fes = useFESContext();
  const viewer = fes.getViewer()?.getViewer();
  const maxZoom = viewer?.viewport.getMaxZoom();
  const zoom = viewer?.viewport.getZoom(true);
  const hasLabels = zoom && maxZoom && zoom >= zoomLevel * maxZoom;
  const [showLabels, setShowLabels] = useState(hasLabels);

  useEffect(() => {
    const handleShowLabelsChange = (params: { showLabels: boolean }) => {
      setShowLabels(params.showLabels);
    };

    fes.on(FES_EVENTS.SHOW_LABELS, handleShowLabelsChange);
    return () => {
      fes.off(FES_EVENTS.SHOW_LABELS, handleShowLabelsChange);
    };
  }, [fes]);

  return { showLabels };
};
