import { GlobalConfigValues } from '@engage-shared/api/buildings/interfaces';
import { Nullable } from '@engage-shared/utils/types';
import { pipe } from '@engage-shared/utils/helpers';
import { ConfigMethod, TenantParsedData } from '@engage-shared/api/tenant/interfaces';

interface ModifiedTenantConfigGetter {
  buildingConfig: BuildingConfig | undefined;
  tenantConfig: TenantParsedData;
}

type TenantConfigUpdated = (arg: TenantParsedData) => TenantParsedData;

type BuildingTriStateProps =
  | 'allDayDeskReservations'
  | 'bookSafe'
  | 'enableDeskCheckIn'
  | 'enforceBookingsAllowed'
  | 'enableHealthDeclaration';

interface BuildingConfig {
  allDayDeskReservations: GlobalConfigValues;
  bookSafe: GlobalConfigValues;
  enableHealthDeclaration: GlobalConfigValues;
  enableDeskCheckIn: GlobalConfigValues;
  enforceBookingsAllowed: GlobalConfigValues;
  healthDeclaration: Nullable<string>;
  deskCheckInDuration: number;
  deskCheckInOpenWindow: number;
  allDayStartTime: string;
  futureBookingLimit: number;
}

const buildingConfigToGlobalConfigValuesMap = {
  [GlobalConfigValues.OFF]: false,
  [GlobalConfigValues.ON]: true,
};

const getBuildingConfigProp = (
  buildingConfig: BuildingConfig,
  propName: BuildingTriStateProps,
): Nullable<boolean> => {
  const prop: Nullable<GlobalConfigValues> = buildingConfig?.[propName];
  //if (!prop) return null;
  if (prop === GlobalConfigValues.GLOBAL) return null;
  return buildingConfigToGlobalConfigValuesMap[prop];
};

const updateTenantConfigFromBuilding =
  (tenantPropName: ConfigMethod, updateValue: Nullable<boolean>): TenantConfigUpdated =>
  (tenantConfig: TenantParsedData): TenantParsedData => {
    if (updateValue === null) return tenantConfig;
    return { ...tenantConfig, [tenantPropName]: updateValue };
  };

export const getModifiedTenantConfig = ({
  buildingConfig,
  tenantConfig,
}: ModifiedTenantConfigGetter) => {
  if (!buildingConfig) return tenantConfig;

  const buildingAllDayReservation = getBuildingConfigProp(buildingConfig, 'allDayDeskReservations');
  const buildingIsCleverSocialDistancingActive = getBuildingConfigProp(buildingConfig, 'bookSafe');
  const buildingEnableHealthDeclaration = getBuildingConfigProp(
    buildingConfig,
    'enableHealthDeclaration',
  );
  const enableDeskCheckIn = getBuildingConfigProp(buildingConfig, 'enableDeskCheckIn');

  return pipe<TenantParsedData>(
    updateTenantConfigFromBuilding(
      ConfigMethod.enforceAllDayDeskReservations,
      buildingAllDayReservation,
    ),
    updateTenantConfigFromBuilding(
      ConfigMethod.isCleverSocialDistancingActive,
      buildingIsCleverSocialDistancingActive,
    ),
    updateTenantConfigFromBuilding(
      ConfigMethod.enableHealthDeclaration,
      buildingEnableHealthDeclaration,
    ),
    updateTenantConfigFromBuilding(
      ConfigMethod.healthDeclaration,
      buildingEnableHealthDeclaration
        ? buildingConfig.healthDeclaration
        : tenantConfig.healthDeclaration,
    ),
    updateTenantConfigFromBuilding(ConfigMethod.enableDeskCheckIn, enableDeskCheckIn),
    updateTenantConfigFromBuilding(
      ConfigMethod.deskCheckInOpenWindow,
      enableDeskCheckIn ? buildingConfig.deskCheckInOpenWindow : tenantConfig.deskCheckInOpenWindow,
    ),
    updateTenantConfigFromBuilding(
      ConfigMethod.deskCheckInWindowDuration,
      enableDeskCheckIn
        ? buildingConfig.deskCheckInDuration
        : tenantConfig.deskCheckInWindowDuration,
    ),
    updateTenantConfigFromBuilding(
      ConfigMethod.allDayReservationCheckInStartTime,
      enableDeskCheckIn
        ? buildingConfig.allDayStartTime
        : tenantConfig.allDayReservationCheckInStartTime,
    ),
    updateTenantConfigFromBuilding(
      ConfigMethod.futureBookingsLimit,
      buildingConfig.futureBookingLimit
        ? buildingConfig.futureBookingLimit
        : tenantConfig.futureBookingsLimit,
    ),
  )(tenantConfig);
};
